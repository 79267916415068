@import "./variables.scss";

html, body { height: 100%; }

html {
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
    font-family: $font-family-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $text-color;
    background-color: $body-bg;
}

[routerLink], .clickable {
    cursor: pointer;
}
[tabindex] {
    outline: none !important;
 }

 .clickable {
   cursor: pointer;
 }

.material-icons.glyphicon {
    top:inherit;
    color:inherit;
    font-size: inherit;
}

.grid-header {
    background-color: #808285;
    color: #fff;
    text-align: center;
    font-weight: 700;
}

.grid-empty {
    background-color: $bg-color;
    transform: scaleY(1.1);
}

.grid-cell-updatable {
    background-color: #f5f5f5;
}

.row {
    padding-top: 6px;
}

button.mat-mdc-button-base {
    margin: 5px;
}

////////// BUTTONS
///
.main-btn, .secondary-btn, .delete-btn {
  border-radius: 20px!important;
  padding: 0px 20px!important;
  font-weight: normal;
  margin-right: 0.5em!important;
  min-height: 40px;
  display: inline-flex;
  align-items: center;
  max-height: 2em;
  cursor: pointer;
  text-transform: uppercase;
  border-color: $main-color;
}
.main-btn {
  background-color: $main-color!important;
  color: white!important;
}
.delete-btn {
  background-color: red;
  color: white!important;
}
.secondary-btn {
  background-color: transparent;
  color: $main-color!important;
}
.icon-btn-main, .icon-btn-secondary {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  border: none;
  align-items: center;
  display: flex;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);

  mat-icon {
    margin: 0;
    width: 100%;
  }
}
.icon-btn-main {
  background-color: $main-color;

  mat-icon {
    color: white;
    stroke: white;
  }
}
.icon-btn-secondary {
  background-color: transparent;

  mat-icon {
    color: $main-color;
    stroke: $main-color;
  }
}

////////// TEXT
///
.italic {
  font-style: italic;
}

h1 {
  font-size: 26px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 18px;
}

.clickable {
  cursor: pointer;
}

////////// DISPLAY
.d-flex-row-align-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.d-flex-column-align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.d-flex-justify-between {
  display: flex;
  justify-content: space-between;
}

.nowrap {
  white-space: nowrap;
}

/////////// COMPONENTS
///
.mat-divider, .mat-divider-vertical {
  height: 100%;
  border-color: $main-color !important;
}
